import React from 'react'

import Header from './header'

import './normalize.css'
import './main.less'

const Layout = ({ children }) => (
  <>
    <div className="acquisition-banner">
      Undefined Labs is now a part of Datadog!{' '}
      <a
        href="https://medium.com/scopedev/undefined-labs-is-now-a-part-of-datadog-b614f246b973"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </div>

    <Header />
    <div className="ribbon" />

    <>{children}</>
  </>
)

export default Layout
