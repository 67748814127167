import { Link } from 'gatsby'
import React from 'react'

import logo from '../images/logo.png'

const Header = () => (
  <header>
    <div className="logo">
      <h1>
        <Link to="/">
          <img src={logo} alt="undefined labs" />
        </Link>
      </h1>
    </div>

    <nav>
      <ul>
        <li>
          <Link to="/about-us">About us</Link>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
